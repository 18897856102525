import { writable } from "svelte/store";
import {
  localizationSetCzech,
  localizationSetEnglish,
  localizationSetRussian,
  localizationSetSpanish,
  localizationSetGerman,
} from "./localizations.js";

let storedLanguage = localStorage.getItem("language");

if (!storedLanguage) {
  storedLanguage = "CZ";
}

export const language = writable(storedLanguage);

let storedSelect = localStorage.getItem("selected");
if (!storedSelect) {
  storedSelect = 0;
}
export const selected = writable(storedSelect);

selected.subscribe((value) => {
  localStorage.setItem("selected", value);
});

let localizationSetLocal;
export let localizationSet = writable(localizationSetLocal);

language.subscribe((value) => {
  localStorage.setItem("language", value);
  loadLocalization(value);
});

let cookiesStored = localStorage.getItem("cookiesAgreed");
if (!cookiesStored) {
  cookiesStored = "false";
}

export const cookiesAgreed = writable(cookiesStored);
cookiesAgreed.subscribe((value) => {
  localStorage.setItem("cookiesAgreed", value);
});

let subPageStored = localStorage.getItem("subPageUri");
if (!subPageStored) {
  subPageStored = "";
}

export const subPageUri = writable(subPageStored);
subPageUri.subscribe((value) => {
  localStorage.setItem("subPageUri", value);
});

function loadLocalization(value) {
  if (value === "CZ") {
    localizationSetLocal = localizationSetCzech;
  } else if (value === "EN") {
    localizationSetLocal = localizationSetEnglish;
  } else if (value === "RU") {
    localizationSetLocal = localizationSetRussian;
  } else if (value === "ES") {
    localizationSetLocal = localizationSetSpanish;
  } else if (value === "DE") {
    localizationSetLocal = localizationSetGerman;
  } else {
    localizationSetLocal = localizationSetCzech;
    language.set("CZ");
    selected.set(0);
  }

  localizationSet.set(localizationSetLocal);
}
