<script>
  import { localizationSet } from "../stores/store.js";
</script>

<div class="footer-container">
  <div class="sections">
    <div class="section1">
      <div class="logo">
        <img src="./favicon.png" alt="" />
        <p>VyberSiJidlo.cz</p>
      </div>
      <div class="slogan">
        <p>
          {$localizationSet.footerSlogan}
        </p>
      </div>
    </div>
    <div class="section2">
      <div class="sec">
        <h1>{$localizationSet.footerSocialMediaText}</h1>
        <a
          href="https://www.facebook.com/people/Vybersijidlocz/100029105215443/"
          >{$localizationSet.footerFacebook}</a
        >
        <a href="https://www.instagram.com/vybersijidlo/"
          >{$localizationSet.footerInstagram}</a
        >
      </div>
    </div>
    <div class="section3">
      <div class="sec">
        <h1>{$localizationSet.footerAboutPage}</h1>
        <a href="/">{$localizationSet.footerPageMap}</a>
        <a href="https://netpioneer.cz">{$localizationSet.footerWebCreator}</a>
      </div>
    </div>
  </div>
  <div class="copyright">&copy; NetPioneer 2021 all rights reserved</div>
</div>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto+Slab&display=swap");

  .footer-container {
    height: auto;
    width: 100%;
    background-color: rgb(27, 26, 23);
  }

  .sections {
    display: flex;
    flex-direction: row;
  }

  .section1,
  .section2,
  .section3 {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }

  .section2 h1,
  .section3 h1 {
    font-size: 22px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    text-decoration: underline;
  }

  .sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    gap: 6px;
    color: white;
  }

  .sec a {
    text-decoration: none;
    color: white;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo img {
    height: 32px;
  }

  .logo p {
    color: white;
    font-family: "Caveat", sans-serif;
    font-size: 24px;
  }

  .slogan {
    padding-top: 20px;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: white;
    text-align: center;
    width: 160px;
  }

  .copyright {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: white;
    padding-bottom: 20px;
  }

  @media (max-width: 480px) {
    .sections {
      flex-direction: column;
      align-items: center;
    }

    .sec {
      align-items: center;
      text-align: center;
      white-space: nowrap;
    }
  }
</style>
