<script>
  import { language, localizationSet, selected } from "../stores/store.js";

  export let restaurantClass = "";
  export let contactClass = "";

  let localSelected;

  let languages = [
    { id: 1, text: `Čeština`, sys: "CZ" },
    { id: 2, text: "English", sys: "EN" },
    { id: 3, text: "Pусский", sys: "RU" },
    { id: 4, text: "Español", sys: "ES" },
    { id: 5, text: "Deutsch", sys: "DE" },
  ];

  localSelected = languages[$selected];

  function toggleLanguage(lang) {
    language.set(lang.sys);

    let index = languages.indexOf(lang);
    selected.set(index);
  }
</script>

<div class="navbar-container">
  <a class="logo" href="/">
    <img src="./favicon.png" alt="" />
    <p>VyberSiJidlo.cz</p>
  </a>
  <div class="links-container">
    <div class="all-links">
      <a href="/restaurace" class="restaurace{restaurantClass}"
        >{$localizationSet.restaurantNavbarButton}</a
      >
      <a href="/contact" class="kontakt{contactClass}"
        >{$localizationSet.contactNavbarButton}</a
      >
      <select
        class="language"
        bind:value={localSelected}
        on:change={() => toggleLanguage(localSelected)}
      >
        {#each languages as lang}
          <option value={lang}>
            {lang.text}
          </option>
        {/each}
      </select>
    </div>
  </div>
</div>

<style>
  .navbar-container {
    height: 80px;
    width: 100%;
    background-color: rgb(110, 88, 65);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
  }

  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 2%;
    cursor: pointer;
    transition: 0.25s;
    animation: fadeIn 1s;
    text-decoration: none;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      translate: -100%;
    }
    100% {
      opacity: 1;
      translate: 0%;
    }
  }
  .logo:hover {
    opacity: 0.6;
    transition: 0.25s;
  }

  .logo img {
    height: 32px;
  }

  .logo p {
    color: white;
    font-family: "Caveat", sans-serif;
    font-size: 24px;
  }

  .links-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2%;
  }

  .all-links {
    display: flex;
    flex-direction: row;
    gap: 4vw;
  }

  .all-links a {
    transition: 00.25s;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }

  .all-links a:hover {
    opacity: 0.6;
    cursor: pointer;
    transition: 00.25s;
  }

  .restaurace,
  .kontakt {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: white;
    font-size: 28px;
  }

  .restauraceactive {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: white;
    font-size: 28px;
    text-decoration: underline;
  }

  .kontaktactive {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    color: white;
    font-size: 28px;
    text-decoration: underline;
  }

  .language {
    width: auto;
    border: none;
    font-family: "Montserrat", sans-serif;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding: 0;
    margin: 0;
    font-size: 24px;
    transition: 00.25s;
    display: flex;
  }

  .language option {
    background-color: rgb(110, 88, 65);
  }

  .language:hover {
    opacity: 0.6;
    transition: 00.25s;
  }

  @media (max-width: 700px) {
    .links-container {
      padding-right: 0;
    }
    .language {
      width: auto;
    }
  }

  @media (max-width: 600px) {
    .navbar-container {
      flex-direction: column;
      gap: 15px;
    }

    .links-container {
      justify-content: center;
    }
  }

  @media (max-width: 470px) {
    .restaurace,
    .kontakt,
    .language,
    .restauraceactive,
    .kontaktactive {
      font-size: 20px;
    }

    .language {
      font-size: 19px;
    }
    .restaurace,
    .kontakt {
      margin-top: 1px;
    }
  }

  @media (max-width: 360px) {
    .all-links {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .all-links select {
      margin-left: 20px;
    }
  }
</style>
