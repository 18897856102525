let czech = {
  restaurantNavbarButton: "Restaurace",
  contactNavbarButton: "Kontakt",
  landingMainText:
    "Nestíháte? Máte návštěvu nebo už cokoliv? Nepanikařte, můžeme vás podržet. Stačí pár kliknutí a jídlo máte přede dveřmi.",
  landingStartOrderingButton: "ZAČNI OBJEDNÁVAT",
  landingSecondText:
    "Ještě nejste rozhodnuti? Nebo rádi byste se podívali na menu restaurací detailněji? Žádný problém! Máme pro vás celý list restaurací a jejich menu tady na stránce, stačí kliknout na tlačítko pod tímto textem.",
  landingMenuButton: "ZOBRAZIT MENU",
  footerSlogan: "Kdekoliv, Kdykoliv, jsme tam pro vás.",
  footerSocialMediaText: "Naše média",
  footerFacebook: "Facebook",
  footerInstagram: "Instagram",
  footerAboutPage: "O stránce",
  footerPageMap: "Mapa stránky",
  footerWebCreator: "Výrobce",
  restaurantsTitle: "RESTAURACE",
  restaurantsKitchen: "Kuchyně",
  contactTitle: "KONTAKT",
  contactOpenings: "Otevírací doba: 10:30 - 20:00",
  contactPhone: "Tel. na Kurýra +420 725 949 020",
  messageHolder: "Napište nám zprávu!",
  messageButton: "Poslat",
  contactSendButton: "Odesláno",
  rateLimited:
    "Odeslali jste příliš mnoho požadavků, zkuste to znovu za chvíli.",
  cookiePopup: "Používáním stránky souhlasíte se zpracováním souborů cookies",
  menuTitle: "MENU",
  orderTitle: "Vaše objednávka",
  orderNow: "objednej nyní",
};

let english = {
  restaurantNavbarButton: "Restaurants",
  contactNavbarButton: "Contact",
  landingMainText:
    "Are you in a rush? Do you have company? Don't worry, we got you. Only a few clicks and your food is infront of your door.",
  landingStartOrderingButton: "START ORDERING",
  landingSecondText:
    "Not decided yet? Or would you like to look at restaurant menus in more detail? No problem! We have a whole list of restaurants menus here on the page, just click on the button below.",
  landingMenuButton: "SHOW MENU",
  footerSlogan: "Wherever, Whenever, we are there for you.",
  footerSocialMediaText: "Our socials",
  footerFacebook: "Facebook",
  footerInstagram: "Instagram",
  footerAboutPage: "About website",
  footerPageMap: "Map of website",
  footerWebCreator: "Creators",
  restaurantsTitle: "RESTAURANTS",
  restaurantsKitchen: "Kitchen type",
  contactTitle: "CONTACT",
  contactOpenings: "Opening times: 10:30 - 20:00",
  contactPhone: "Courrier phone: +420 725 949 020",
  messageHolder: "Write us a message!",
  messageButton: "Send",
  contactSendButton: "Sent",
  rateLimited: "You have sent too many requests, wait before repeating.",
  cookiePopup: "By using our site, you agree with using cookies",
  menuTitle: "MENU",
  orderTitle: "Your order",
  orderNow: "order now",
};

let russian = {
  restaurantNavbarButton: "Рестораны",
  contactNavbarButton: "Контакт",
  landingMainText:
    "Не успеваете? У вас гости или просто ужин с семьей? Не паникуйте, мы спасем вас. Всего несколько щелчков мышью - и еда у дверей.",
  landingStartOrderingButton: "НАЧНИ ЗАКАЗЫВАТЬ",
  landingSecondText:
    "Еще не решили? Или хотите более детально изучить меню ресторанов? Нет проблем! У нас есть целый список меню ресторанов на этой странице, просто нажмите на кнопку ниже.",
  landingMenuButton: "ПОКАЗАТЬ МЕНЮ",
  footerSlogan: "В любом месте, в любое время, мы будем там для вас.",
  footerSocialMediaText: "Наши социальные сети",
  footerFacebook: "Facebook",
  footerInstagram: "Instagram",
  footerAboutPage: "О сайте",
  footerPageMap: "Карта сайта",
  footerWebCreator: "Разработчик",
  restaurantsTitle: "РЕСТОРАН",
  restaurantsKitchen: "Кухня",
  contactTitle: "КОНТАКТ",
  contactOpenings: "Часы работы: 10:30 - 20:00",
  contactPhone: "Телефон курьера +420 725 949 020",
  messageHolder: "Напишите нам!",
  messageButton: "Отправить",
  contactSendButton: "Отправил",
  rateLimited:
    "Вы отправили слишком много запросов, подождите перед повторением.",
  cookiePopup: "Используя наш сайт, вы соглашаетесь с использованием файлов cookie",
  menuTitle: "MENU",
  orderTitle: "Ваш заказ",
  orderNow: "Заказать сейчас",
};

let spanish = {
  restaurantNavbarButton: "Restaurantes",
  contactNavbarButton: "Contacto",
  landingMainText:
    "¿Tienes prisa? ¿Tienes compañía? No te preocupes, te tenemos. Sólo unos pocos clics y tu comida estará delante de tu puerta",
  landingStartOrderingButton: "EMPEZAR A PEDIR",
  landingSecondText:
    "¿Aún no se ha decidido? ¿O quiere ver los menús de los restaurantes con más detalle? No hay problema. Tenemos una lista completa de menús de restaurantes aquí en la página, sólo tiene que hacer clic en el botón de abajo.",
  landingMenuButton: "MUESTRAME EL MENU",
  footerSlogan: "Donde sea, cuando sea, estamos ahí para ti",
  footerSocialMediaText: "Nuestras redes sociales",
  footerFacebook: "Facebook",
  footerInstagram: "Instagram",
  footerAboutPage: "Acerca del sitio web",
  footerPageMap: "Mapa del sitio web",
  footerWebCreator: "Creadores",
  restaurantsTitle: "RESTAURANTES",
  restaurantsKitchen: "Tipo de cocina",
  contactTitle: "CONTACTO",
  contactOpenings: "Horario de apertura: 10:30 - 20:00",
  contactPhone: "Teléfono de la mensajería: +420 725 949 020",
  messageHolder: "¡Escríbenos un mensaje!",
  messageButton: "Enviar",
  contactSendButton: "Enviado",
  rateLimited: "Ha enviado demasiadas solicitudes, espere antes de repetir.",
  cookiePopup: "Al utilizar nuestro sitio, usted acepta el uso de cookies",
  menuTitle: "MENÚ",
  orderTitle: "Su pedido",
  orderNow: "pedir ahora",
};

let german = {
  restaurantNavbarButton: "Restaurants",
  contactNavbarButton: "Kontakt",
  landingMainText:
    "Sind Sie in Eile? Haben Sie Besuch? Keine Sorge, wir haben Sie. Nur ein paar Klicks und Ihr Essen steht vor Ihrer Tür.",
  landingStartOrderingButton: "BESTELLUNG STARTEN",
  landingSecondText:
    "Sie haben sich noch nicht entschieden? Oder möchten Sie sich die Speisekarten der Restaurants genauer ansehen? Das ist kein Problem! Wir haben eine ganze Liste von Speisekarten hier auf der Seite, klicken Sie einfach auf die Schaltfläche unten.",
  landingMenuButton: "ANZIEGEN MENU",
  footerSlogan: "Wo auch immer, wann auch immer, wir sind für Sie da.",
  footerSocialMediaText: "Unsere Socials",
  footerFacebook: "Facebook",
  footerInstagram: "Instagram",
  footerAboutPage: "Über die Website",
  footerPageMap: "Karte der Website",
  footerWebCreator: "Schöpfer",
  restaurantsTitle: "RESTAURANTS",
  restaurantsKitchen: "Küchentyp",
  contactTitle: "KONTAKT",
  contactOpenings: "Öffnungszeiten: 10:30 - 20:00",
  contactPhone: "Kurier-Telefon: +420 725 949 020",
  messageHolder: "Schreiben Sie uns eine Nachricht!",
  messageButton: "Senden",
  contactSendButton: "Gesendet",
  rateLimited:
    "Sie haben zu viele Anfragen gesendet, warten Sie, bevor Sie sie wiederholen.",
  cookiePopup: "Durch die Nutzung unserer Website erklären Sie sich mit der Verwendung von Cookies einverstanden",
  menuTitle: "MENU",
  orderTitle: "Ihre Bestellung",
  orderNow: "Jetzt bestellen",
};

export let localizationSetCzech = czech;
export let localizationSetEnglish = english;
export let localizationSetRussian = russian;
export let localizationSetSpanish = spanish;
export let localizationSetGerman = german;
