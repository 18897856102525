<script>
  import { cookiesAgreed } from "./stores/store.js";
  import Navbar from "./components/Navbar.svelte";
  import Landingpage from "./components/Landingpage.svelte";
  import Footer from "./components/Footer.svelte";
  import CookiePopup from "./components/CookiePopup.svelte";
</script>

<Navbar />
<Landingpage />
{#if $cookiesAgreed === "false"}
  <CookiePopup />
{/if}
<Footer />
