<script>
  import { localizationSet, cookiesAgreed } from "../stores/store.js";

  function agree() {
    cookiesAgreed.set("true");
  }
</script>

<div class="wrapper">
  <p>{$localizationSet.cookiePopup}</p>
  <!-- svelte-ignore a11y-missing-attribute -->
  <a on:click={agree}>OK</a>
</div>

<style>
  .wrapper {
    font-family: "Montserrat", sans-serif;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgb(66, 65, 65);
    opacity: 0.6;
    width: 100%;
    padding: 15px;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .wrapper a {
    padding: 5px;
    margin-right: 40px;
    color: black;
    background-color: white;
    width: 70px;
    border: none;
    text-align: center;
    border-radius: 3px;
    transition: 0.25s;

    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .wrapper a:hover {
    text-decoration: none;
    opacity: 0.8;
    transition: 0.25s;
  }
</style>
